import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateVick",
  "categories": ["rails", "docker"],
  "date": "2019-12-17",
  "path": "/blog/rails-development-with-docker",
  "summary": "Hint's approach to Docker Rails development environments.",
  "title": "Rails Development with Docker",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@natron99"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As a software consultancy, we switch between many projects throughout the year. A critical factor in delivering value is the ease at which we are able to move between projects.`}</p>
    <p>{`Over the years, we have used many tools to manage dependencies needed to run and develop our clients' projects. The problem with most tools has been the ability to have consistent, reproducible development environments across our team. About two years ago, we discovered that Docker was a viable option for building consistent development environments. Since then, we continue to iterate on our configuration as we learn new ways to handle the complexity of the projects while simplifying the setup process for our team.`}</p>
    <p>{`In this guide, we will cover the basics of our Docker development environment for Rails.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`If you would like to follow along, `}<a parentName="p" {...{
        "href": "https://hub.docker.com/search/?type=edition&offering=community"
      }}>{`install Docker CE`}</a>{` and create, `}<a parentName="p" {...{
        "href": "https://github.com/hintmedia/base-rails-app"
      }}>{`clone`}</a>{`, or have a working Rails app.`}</p>
    <p>{`We will be using a combination of Dockerfiles, Docker Compose, and bash scripts throughout this guide, so let's make a place for most of those files to live.
Start by creating a `}<inlineCode parentName="p">{`docker`}</inlineCode>{` folder in the root of the Rails project. Here we will store Dockerfiles and bash scripts to be referenced from the `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file we will be creating later.`}</p>
    <p>{`Inside the `}<inlineCode parentName="p">{`docker`}</inlineCode>{` folder, create another folder named `}<inlineCode parentName="p">{`ruby`}</inlineCode>{`. Inside the newly created `}<inlineCode parentName="p">{`ruby`}</inlineCode>{` folder, create a file named `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{`. This file will contain commands to build a custom image for your Rails app.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "241px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "39.877300613496935%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAABP+AAAT/gEHlDmEAAAA8ElEQVQY043P20rDQBSF4bxJM+fjzkxmTGYSk1gbUQtWi6UiQgXf/yWkN161tvDffrBWYX1y4dbGzg8jtL0fJki99klBc7Giin3u53i/uvvaDh+b6fNteXi3sdPQ6Kr9v4KpmghHpGPSU+4Id5Q7puprKhDTuoouZBGin6eblweT25JoxMzFCsQM157LioGDsffzZPtuUUpETYlViRWi5zHmlnDDXaKQqHUcaqJAhEB0pdtG50RUdc4fMaaq2f3Um28Ycrdft9vHvHvu9uvwtBwPr241LkqJuT09mwggAhDViBssLZaABWBuj89PmT/8C29pVIifhxjyAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "initial Docker directories",
        "title": "initial Docker directories",
        "src": "/static/6114a0bd4d92449898365d9fbf747ce0/2f862/initial-dirs.png",
        "srcSet": ["/static/6114a0bd4d92449898365d9fbf747ce0/222b7/initial-dirs.png 163w", "/static/6114a0bd4d92449898365d9fbf747ce0/2f862/initial-dirs.png 241w"],
        "sizes": "(max-width: 241px) 100vw, 241px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Hello Dockerfile`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`RUBY_VERSION`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2.6`}</span>{`
  FROM ruby:`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$RUBY_VERSION`}</span>{`
  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`DEBIAN_FRONTEND`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`noninteractive`}</code></pre></div>
    <p>{`In this block, we set the `}<inlineCode parentName="p">{`RUBY_VERSION`}</inlineCode>{` and `}<inlineCode parentName="p">{`DEBIAN_FRONTEND`}</inlineCode>{` build arguments and specify the docker image we will use as our base image.`}</p>
    <p>{`The first `}<inlineCode parentName="p">{`ARG`}</inlineCode>{` sets a default value for `}<inlineCode parentName="p">{`RUBY_VERSION`}</inlineCode>{`, but passing in a value from the command line or a `}<inlineCode parentName="p">{`docker-compose`}</inlineCode>{` file will override it, as you'll see later in the guide. An `}<inlineCode parentName="p">{`ARG`}</inlineCode>{` defined before `}<inlineCode parentName="p">{`FROM`}</inlineCode>{` is only available for use in `}<inlineCode parentName="p">{`FROM`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`FROM`}</inlineCode>{` in a `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` is the base image for building our image and the start of the build stage. In our case, we are using the official Ruby image from Docker Hub, defaulting to Ruby 2.6.`}</p>
    <p>{`The next `}<inlineCode parentName="p">{`ARG`}</inlineCode>{` is used to set the build stage shell to `}<inlineCode parentName="p">{`noninteractive`}</inlineCode>{` mode, which is the general expectation during the build process. We don't want this environment variable to carry over to when we are using the images in our development environment, which is why we are using `}<inlineCode parentName="p">{`ARG`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`ENV`}</inlineCode>{`.`}</p>
    <h3>{`Base Software Install`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`NODE_VERSION`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{`
  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -sL https://deb.nodesource.com/setup_`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$NODE_VERSION`}</span>{`.x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{` -
  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -sS https://dl.yarnpkg.com/debian/pubkey.gpg `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` - `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"deb https://dl.yarnpkg.com/debian/ stable main"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tee`}</span>{` /etc/apt/sources.list.d/yarn.list

  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    build-essential `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    nodejs `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    locales `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    netcat `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`vim`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span></code></pre></div>
    <p>{`Here we are setting up the necessary software and tools for running a modern Rails app. Like in the previous section, we are setting a default for our `}<inlineCode parentName="p">{`NODE_VERSION`}</inlineCode>{` build argument.`}</p>
    <p>{`The next two `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` lines set up the defined version of the node apt repository and the latest stable yarn apt repo. Since Webpacker started being officially supported in Rails 5.1, it is important we have a recent version of node and yarn available in the image we will be running Rails on.`}</p>
    <p>{`The third `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` will look familiar if you have used any Debian based OS. It updates the apt repositories, which is important since we just installed two new ones, and then it installs our base software and tools.`}</p>
    <p>{`I want to point out `}<inlineCode parentName="p">{`netcat`}</inlineCode>{` and `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` specifically. `}<inlineCode parentName="p">{`netcat`}</inlineCode>{` is a networking tool we will use to verify the other services are up when we are bringing up our Rails app through Docker Compose. We install `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` since by default it is not installed on the Debian based Docker images, and we will be using a non-root user in our Docker image.`}</p>
    <h3>{`Non-root User`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  ARG `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`UID`}</span>{`
  ENV `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`UID`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$UID`}</span>{`
  ARG GID
  ENV GID `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$GID`}</span>{`
  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}><span parentName="span" {...{
              "className": "token environment constant"
            }}>{`USER`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`ruby
  ENV `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`USER`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`

  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`groupadd`}</span>{` -g `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$GID`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` -u `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$UID`}</span>{` -g `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` -m `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -p `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -aG `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
      `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`$USER`}</span>{` ALL=NOPASSWD: ALL"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` /etc/sudoers.d/50-`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span></code></pre></div>
    <p>{`Docker containers generally use the root user, which is not inherently bad, but is problematic for file permissions in a development environment. Our solution for this is to create a non-root user and pass in our `}<inlineCode parentName="p">{`UID`}</inlineCode>{`, `}<inlineCode parentName="p">{`GID`}</inlineCode>{`, and username as build arguments.`}</p>
    <p>{`If we pass in our `}<inlineCode parentName="p">{`UID`}</inlineCode>{` and `}<inlineCode parentName="p">{`GID`}</inlineCode>{`, all files created or modified by the user in the container will share the same permissions as our user on the host machine.`}</p>
    <p>{`You will notice here that we use the build arguments to set the environment variable (via `}<inlineCode parentName="p">{`ENV`}</inlineCode>{`) since we will also want these variables available when we bring up the container.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` instruction we add a standard Linux user/group and then we add the new user to the `}<inlineCode parentName="p">{`sudoers`}</inlineCode>{` file with no password. This gives us all the benefits of running as root while keeping file permissions correct.`}</p>
    <h3>{`Ruby, RubyGems, and Bundler Defaults`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  ENV `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`LANG`}</span>{` C.UTF-8

  ENV BUNDLE_PATH /gems
  ENV BUNDLE_HOME /gems

  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`BUNDLE_JOBS`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`
  ENV BUNDLE_JOBS `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$BUNDLE_JOBS`}</span>{`
  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`BUNDLE_RETRY`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
  ENV BUNDLE_RETRY `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$BUNDLE_RETRY`}</span>{`

  ENV GEM_HOME /gems
  ENV GEM_PATH /gems

  ENV `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`PATH`}</span>{` /gems/bin:`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$PATH`}</span></code></pre></div>
    <p>{`Explicitly setting the `}<inlineCode parentName="p">{`LANG`}</inlineCode>{` environment variable specifies the fallback locale setting for the image. `}<inlineCode parentName="p">{`UTF-8`}</inlineCode>{` is a sane fallback and is what locale defaults to when it's working properly.`}</p>
    <p>{`We will be using a `}<inlineCode parentName="p">{`volume`}</inlineCode>{` with Compose, so we need to point RubyGems and Bundler to where that `}<inlineCode parentName="p">{`volume`}</inlineCode>{` will mount in the file system. We also set the gem executables in the path and set some defaults for Bundler, which are configurable via build arguments.`}</p>
    <h4>{`Optional Software Install`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#-----------------`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Postgres Client:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#-----------------`}</span>{`
  ARG `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`INSTALL_PG_CLIENT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false

  RUN `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$INSTALL_PG_CLIENT`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y postgresql-client `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span></code></pre></div>
    <p>{`Here is an example of how to set up optional software installs in the Dockerfile. In this scenario, `}<inlineCode parentName="p">{`postgresql-client`}</inlineCode>{` will not be installed by default, but will be installed if we pass a build argument set to true (`}<inlineCode parentName="p">{`INSTALL_PG_CLIENT=true`}</inlineCode>{`).`}</p>
    <h3>{`Dockerfile Final Touches`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$GEM_HOME`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$GEM_HOME`}</span>{`"`}</span>{`
  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` /app

  WORKDIR /app

  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p node_modules `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` node_modules
  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p public/packs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` public/packs
  RUN `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p tmp/cache `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{` tmp/cache

  `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`USER`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`

  RUN gem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` bundler`}</code></pre></div>
    <p>{`To wrap up the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{`, we create and set permissions on needed directories that were referenced previously in the file or that we will be setting up as volumes with Docker Compose.`}</p>
    <p>{`We call `}<inlineCode parentName="p">{`USER $USER`}</inlineCode>{` here at the end of the file, which will set the user for the image when you boot it as a container.`}</p>
    <p>{`The last `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` command installs Bundler, which may not be required depending on the version of Ruby.`}</p>
    <p>{`Next, we'll take a look at our `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file.`}</p>
    <h2>{`Docker Compose`}</h2>
    <p>{`Docker's definition of Compose is a great place to start.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Compose is a tool for defining and running multi-container Docker applications. With Compose, you use a YAML file to configure your application's services. Then, with a single command, you create and start all the services from your configuration.`}</p>
    </blockquote>
    <p>{`That is what we are going to be doing in this section of the guide, adding support for a multi-container Docker application.`}</p>
    <p>{`The first thing we will need is a `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file at the root of the Rails app. If you would like to copy and paste the following YAML into a `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file, I'll breakdown its parts below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3.7'`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ./docker/ruby
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` RUBY_VERSION=2.6
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` BUNDLE_JOBS=15
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` BUNDLE_RETRY=2
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` NODE_VERSION=12
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` INSTALL_PG_CLIENT=true
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` UID=500
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` GID=500
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` DATABASE_USER=postgres
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` DATABASE_HOST=postgres
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entrypoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker/ruby/entrypoint.sh
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` .`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cached
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` gems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/gems
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` node_modules`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/node_modules
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` packs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/public/packs
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rails_cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/tmp/cache
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000:3000"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ruby
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`stdin_open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` postgres
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` POSTGRES_HOST_AUTH_METHOD=trust
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/var/lib/postgresql/data

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`gems`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`node_modules`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`packs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    rails_cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span></code></pre></div>
    <p>{`In this file, we are defining two services: the `}<inlineCode parentName="p">{`rails`}</inlineCode>{` service, which our Rails app will run in, and the `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` service, which will accommodate PostgreSQL. The names for these services are arbitrary and could easily be `}<inlineCode parentName="p">{`foo`}</inlineCode>{` and `}<inlineCode parentName="p">{`bar`}</inlineCode>{`, but since the service names are used for building, starting, stopping, and networking, I would recommend naming them close to the actual service they are running.`}</p>
    <p>{`We also are setting our Compose file compatibility to `}<inlineCode parentName="p">{`version: '3.7'`}</inlineCode>{` which is the latest at the time of this writing.`}</p>
    <h3>{`The App Service Broken Down`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3.7'`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ./docker/ruby
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`args`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` RUBY_VERSION=2.6
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` BUNDLE_JOBS=15
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` BUNDLE_RETRY=2
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` NODE_VERSION=12
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` INSTALL_PG_CLIENT=true
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` UID=500
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` GID=500`}</code></pre></div>
    <p>{`The first part of the `}<inlineCode parentName="p">{`rails`}</inlineCode>{` service is to set up to build the image from the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` we created earlier. Once built, it uses the local image from that point forward. We set the `}<inlineCode parentName="p">{`context`}</inlineCode>{`, which is the directory path where our `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` is stored. The `}<inlineCode parentName="p">{`args:`}</inlineCode>{` key specifies the build arguments we set up in the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` earlier in the guide. Notice that we are overriding some of our earlier defaults here.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      . . .
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` DATABASE_USER=postgres
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` DATABASE_HOST=postgres
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entrypoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker/ruby/entrypoint.sh
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` .`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cached
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` gems`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/gems
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` node_modules`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/node_modules
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` packs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/public/packs
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rails_cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/app/tmp/cache
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000:3000"`}</span></code></pre></div>
    <p>{`In the next part, we set up `}<inlineCode parentName="p">{`environment`}</inlineCode>{` variables for connecting to the `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` service, which will require updating your `}<inlineCode parentName="p">{`database.yml`}</inlineCode><a parentName="p" {...{
        "href": "https://gist.github.com/nvick/c4e80964c7a7990b3bf4387de2f2b5b6"
      }}>{`example`}</a>{` file to take advantage of these variables.`}</p>
    <p>{`We also set the default `}<inlineCode parentName="p">{`command`}</inlineCode>{` that will run when `}<inlineCode parentName="p">{`docker-compose up`}</inlineCode>{` runs, which in this case starts the Rails server on port 3000 and binds it to all IP addresses.`}</p>
    <p>{`Next, we point to our `}<inlineCode parentName="p">{`entrypoint`}</inlineCode>{` script, which we'll revisit after breaking down the rest of the Compose file.`}</p>
    <p>{`After the `}<inlineCode parentName="p">{`entrypoint`}</inlineCode>{`, we set up `}<inlineCode parentName="p">{`volumes`}</inlineCode>{`.`}</p>
    <p>{`The first volume mounts the current directory (`}<inlineCode parentName="p">{`.`}</inlineCode>{`) to `}<inlineCode parentName="p">{`/app`}</inlineCode>{` in the container. The mapping for this one is `}<inlineCode parentName="p">{`HOST:CONTAINER`}</inlineCode>{`. If you are on Mac, I would recommend using `}<inlineCode parentName="p">{`.:/app:cached`}</inlineCode>{` since file sharing on Docker for Mac is CPU bound. By setting `}<inlineCode parentName="p">{`:cached`}</inlineCode>{` on the mount point it allows files to be out of sync with the host being authoritative. `}<a parentName="p" {...{
        "href": "https://docs.docker.com/docker-for-mac/osxfs-caching/"
      }}>{`Here are more details`}</a>{` about file sharing performance on Docker for Mac. In our testing `}<inlineCode parentName="p">{`:cached`}</inlineCode>{` has been the best option for speed vs. tradeoffs.`}</p>
    <p>{`The next volumes are named volumes. Creating them happens when we bring up the containers for the first time and then they are persistent from up and down. These volumes are native to the Docker environment, so they operate at native speeds. The persistence and speed are why we have chosen to use them for `}<inlineCode parentName="p">{`gem`}</inlineCode>{`, `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`, `}<inlineCode parentName="p">{`packs`}</inlineCode>{`, and `}<inlineCode parentName="p">{`rails_cache`}</inlineCode>{` storage; otherwise, we would have to reinstall both every time we bring our environment back up. Lastly, there is a top-level `}<inlineCode parentName="p">{`volumes`}</inlineCode>{` key at the very bottom of the `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file, which is where they are defined.`}</p>
    <p>{`The last key in this section is `}<inlineCode parentName="p">{`ports`}</inlineCode>{`. Ports `}<strong parentName="p">{`only`}</strong>{` need to be defined to access the containers from the host otherwise container to container communication happens on the internal Docker network generally using service names.`}</p>
    <p>{`The mapping `}<inlineCode parentName="p">{`"3000:3000"`}</inlineCode>{` allows us to connect to the Rails server at `}<inlineCode parentName="p">{`[localhost:3000](http://localhost:3000)`}</inlineCode>{`. The mapping is `}<inlineCode parentName="p">{`HOST:CONTAINER`}</inlineCode>{` just like the volume mount, and it is recommended to pass them in as strings because YAML parses numbers in `}<inlineCode parentName="p">{`xx:yy`}</inlineCode>{` as base-60.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      . . .
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ruby
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`stdin_open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` postgres`}</code></pre></div>
    <p>{`This last section explicitly sets the `}<inlineCode parentName="p">{`user`}</inlineCode>{` to `}<inlineCode parentName="p">{`ruby`}</inlineCode>{`, which was set up in our `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` above.`}</p>
    <p>{`Use the next two keys: `}<inlineCode parentName="p">{`tty`}</inlineCode>{` and `}<inlineCode parentName="p">{`stdin_open`}</inlineCode>{`, for debugging with `}<inlineCode parentName="p">{`binding.pry`}</inlineCode>{` while hitting the Rails server. `}<a parentName="p" {...{
        "href": "https://gist.github.com/nvick/b4458670f9395de7d19a8e2d06894d9b"
      }}>{`Check out this gist`}</a>{` for more info.`}</p>
    <p>{`One thing to call out from that gist is to use `}<strong parentName="p">{`ctrl-p + ctrl-q`}</strong>{` to detach from the Docker container and leave it in a running state. The `}<inlineCode parentName="p">{`depends_on`}</inlineCode>{` key is used to declare other containers that are required to start with the service. Currently, it is only dependent on `}<inlineCode parentName="p">{`postgres`}</inlineCode>{`. No other health checks or validations are run; we will handle those in the `}<inlineCode parentName="p">{`entrypoint`}</inlineCode>{`.`}</p>
    <h3>{`The Postgres Service`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      . . .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` POSTGRES_HOST_AUTH_METHOD=trust
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/var/lib/postgresql/data`}</code></pre></div>
    <p>{`We define our `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` service next with pretty minimal configuration compared to what we went through for the `}<inlineCode parentName="p">{`rails`}</inlineCode>{` service.`}</p>
    <p>{`The first key, `}<inlineCode parentName="p">{`image`}</inlineCode>{`, will check locally first, then download the official postgres image from Docker Hub with a matching tag if needed. In this case, it will pull in `}<inlineCode parentName="p">{`11.5`}</inlineCode>{`. I chose 11 for this example because that is now the default on Heroku, but there are lots of image options available on Docker Hub.`}</p>
    <p>{`We are using a named volume here as well for our `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` data.`}</p>
    <p>{`And, that wraps up the Compose file.`}</p>
    <h2>{`Enter The Entrypoint`}</h2>
    <p>{`There are a few requirements for starting a Rails server, e.g., the database running and accepting connections. We use the entrypoint, which is a bash script, to fulfill those requirements. Looking back at the compose file we should create this bash script at `}<inlineCode parentName="p">{`docker/ruby/entrypoint.sh`}</inlineCode>{` and make it executable.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#! /bin/bash`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` -e

  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${APP_PATH`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:=`}</span>{`"`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`app"}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${APP_TEMP_PATH`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:=`}</span>{`"$APP_PATH`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`tmp"}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${APP_SETUP_LOCK`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:=`}</span>{`"$APP_TEMP_PATH`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`setup.lock"}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${APP_SETUP_WAIT`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:=`}</span>{`"5"}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 1: Define the functions to lock and unlock our app container's setup`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# processes:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`lock_setup`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$APP_TEMP_PATH`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$APP_SETUP_LOCK`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`unlock_setup`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -rf `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$APP_SETUP_LOCK`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`wait_setup`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Waiting for app setup to finish..."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$APP_SETUP_WAIT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 2: 'Unlock' the setup process if the script exits prematurely:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`trap`}</span>{` unlock_setup HUP INT QUIT KILL `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`TERM`}</span>{` EXIT

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 3: Wait for postgres to come up`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DB is not ready, sleeping..."`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`until`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nc`}</span>{` -vz postgres `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&>`}</span>{`/dev/null`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`done`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DB is ready, starting Rails."`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 4: Specify a default command, in case it wasn't issued:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` -z `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$1`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` -- bundle `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` rails server -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3000`}</span>{` -b `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0 `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$@`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 5: Run the checks only if the app code is executed:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$3`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"rails"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Clean up any orphaned lock file`}</span>{`
    unlock_setup
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 6: Wait until the setup 'lock' file no longer exists:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` -f `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$APP_SETUP_LOCK`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` wait_setup`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`done`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 7: 'Lock' the setup process, to prevent a race condition when the`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# project's app containers will try to install gems and set up the`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# database concurrently:`}</span>{`
    lock_setup
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 8: Check if dependencies need to be installed and install them`}</span>{`
    bundle `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 9: Run migrations or set up the database if it doesn't exist`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Rails >= 6`}</span>{`
    bundle `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` rails db:prepare
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Rails < 6`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# bundle exec rake db:migrate 2>/dev/null || bundle exec rake db:setup`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 10: 'Unlock' the setup process:`}</span>{`
    unlock_setup

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 11: If the command to execute is 'rails server', then we must remove any`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# pid file present. Suddenly killing and removing app containers might leave`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# this file, and prevent rails from starting-up if present:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$4`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$4`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"server"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -rf /app/tmp/pids/server.pid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 12: Replace the shell with the given command:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$@`}</span>{`"`}</span></code></pre></div>
    <p>{`I will only call out a few things specific to working with Rails from this file. Below, we are using `}<inlineCode parentName="p">{`nc`}</inlineCode>{`(`}<a parentName="p" {...{
        "href": "https://www.digitalocean.com/community/tutorials/how-to-use-netcat-to-establish-and-test-tcp-and-udp-connections-on-a-vps#how-to-use-netcat-for-port-scanning"
      }}>{`netcat`}</a>{`) to verify that `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` is up before running any database commands. `}<inlineCode parentName="p">{`nc`}</inlineCode>{` doesn't just ping the server; it is checking that the service is responding on a specific port. This check runs every second until it boots. `}<strong parentName="p">{`Note:`}</strong>{` We are using the service name `}<inlineCode parentName="p">{`postgres`}</inlineCode>{` to connect to the container.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DB is not ready, sleeping..."`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`until`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`nc`}</span>{` -vz postgres `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&>`}</span>{`/dev/null`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`done`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DB is ready, starting Rails."`}</span></code></pre></div>
    <p>{`Next, we run our bundle and yarn install commands to make sure we have the latest dependencies. Once both of those have run, we will use the new Rails 6 `}<inlineCode parentName="p">{`db:prepare`}</inlineCode>{` method to either set up the database or run migrations. This can be handled in many different ways but my preference is to use Rails tools. (Note: the comment is how you would do it on Rails 5 or older)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  bundle `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Rails >= 6`}</span>{`
    bundle `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` rails db:prepare
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Rails < 6`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# bundle exec rake db:migrate 2>/dev/null || bundle exec rake db:setup`}</span></code></pre></div>
    <p>{`Lastly, we check for any dangling PID files left from killing and removing the app containers. If the PID files are left our `}<inlineCode parentName="p">{`rails server`}</inlineCode>{` command will fail.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$4`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$4`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"server"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -rf /app/tmp/pids/server.pid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span></code></pre></div>
    <h2>{`Start It Up`}</h2>
    <p>{`With all of this in place, we run `}<inlineCode parentName="p">{`docker-compose up`}</inlineCode>{`, which will build and start our containers. It is also a great time to get coffee because building the app container will take some time. Once everything is built and up, point your browser at `}<a parentName="p" {...{
        "href": "http://localhost:3000"
      }}>{`localhost:3000`}</a>{` and your homepage, or if this is a new app, it will display the default Rails homepage. If you need to run specs or any other Rails command, running `}<inlineCode parentName="p">{`docker-compose exec app bash`}</inlineCode>{` in a separate terminal will launch a Bash session on the running app container.`}</p>
    <h2>{`Wrapping Up`}</h2>
    <p>{`We now have a base-level Rails Docker development environment. `}<a parentName="p" {...{
        "href": "https://gist.github.com/nvick/96c22b18b6cb31fa458fafba32fa000f"
      }}>{`Here is a gist`}</a>{` of all the three files referenced in this guide. Now that you have an understanding of how to set up a Docker development environment, check out `}<a parentName="p" {...{
        "href": "https://github.com/hintmedia/railsdock"
      }}>{`Railsdock`}</a>{`! It is a CLI tool we are working on that will generate most of this config for you. PR's appreciated!`}</p>
    <p>{`In the next part of the series, we will set up more services and use Compose features to share config between similar services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      